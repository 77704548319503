body {
  font-family: "Raleway", sans-serif;
  color: #5f626a;
  /* background-image: url(https://thumbs.gfycat.com/GoldenHoarseFinnishspitz-size_restricted.gif); */
  background-image: url(https://i.imgur.com/GaIR8N4.gif);
  /* background-color: #8a8c92; */
  /* background-image: url(https://i.imgur.com/ZVw83hI.png); */
  /* background-size: 120%;
  background-position: 43%; */
}

.header {
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 25px;
  color: #1f4fc0;
  position: absolute;
  left: 50%;
  top: 44%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

h1 {
  font-family: Courier New;
  font-weight: 500;
}

#navRight {
  position: fixed;
  top: 90px;
  right: 90px;
  text-align: right;
}

#navBottom {
  position: fixed;
  bottom: 90px;
  right: 90px;
}

#navButtons {
  background: none;
  border: none;
  font-size: 16px;
  color: #5f626a;
  padding-bottom: 10px;
  font-family: "Raleway", sans-serif;
  cursor: pointer;
  text-decoration: none;
}

#navButtons:hover {
  color: #1f4fc0;
}

#project {
  margin: 70px 600px 100px 100px;
  line-height: 28px;
  max-width: 100%;
  padding-bottom: 100px;
}

/* #aboutMe {
  margin: -25%;
} */

a {
  color: #2c2e32;
  text-decoration: underline;
}

a:hover {
  color: #1f4fc0;
}

.item.active {
  width: 94%;
  border-radius: 50px !important;
}

.carousel-item.img {
  width: 94%;
}

#bubble {
  position: fixed;
  /* display: inline-block; */
  bottom: 0;
  background: transparent;
  border-radius: 50%;
  pointer-events: none;
  box-shadow: inset 0 0 12px rgb(255, 255, 255);
  border: 1px #dcdee8 solid;
  animation: animate 18s linear infinite;
  width: 60px;
  height: 60px;
  z-index: 1;
}

@keyframes animate {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(-100vh);
    opacity: 1;
  }
}

#sb {
  position: relative;
  max-width: 37.2em;
  background-color: #dee1eec7;
  padding: 1.125em 1.5em;
  border-radius: 1rem;
  border: 1px #dcdee8 solid;
  margin-bottom: 22px;
}

#headshot {
  height: 230px;
  width: 230px;
  object-fit: cover;
  object-position: center 10%;
  border-radius: 50%;
  border: 1px #dcdee8 solid;
  right: 45%;
  position: fixed;
  opacity: 98%;
}

video {
  border-radius: 50px;
  border: 1px solid rgb(220, 222, 232);
  margin-left: 40px;
  padding-left: 0;
  max-height: 500px;
}

#noteImgs {
  width: 95%;
  height: auto;
}

.column {
  float: left;
}

.left {
  width: 75%;
}

/* .right {
  width: 80%;
} */

#topNav {
  visibility: hidden;
  z-index: 2;
  position: relative;
  font-size: 14px;
}

.nav-link:hover {
  color: #1f4fc0 !important;
}

.navbar-brand {
  font-size: 18px;
}

#navLink {
  color: #5f626a;
}

.dropdown-item:hover {
  background-color: #f7f7f7 !important;
}

#navLink:hover {
  color: #1f4fc0 !important;
}

.navbar-toggler:focus {
  box-shadow: none;
}

#projectHeader {
  line-height: 0px;
}
#projectSub {
  line-height: 3.8;
  font-size: 15px;
  font-weight: 1000px;
}

@media (max-width: 1555px) {
  #headshot {
    display: none;
  }
  .left {
    width: 180%;
  }
  .right {
    width: 160%;
  }
  #defNav {
    visibility: hidden;
  }
  #topNav {
    visibility: visible;
  }
  #projectHeader {
    line-height: 1.2;
  }
  #projectSub {
    line-height: 1.8;
  }
}

@media (max-width: 1113px) {
  .left {
    width: 100%;
  }
  .right {
    width: 100%;
  }
  #project {
    margin: 70px 50px 100px 50px;
    line-height: 28px;
    padding-bottom: 100px;
  }
  #projectHeader {
    line-height: 1.2;
  }
  #projectSub {
    line-height: 1.8;
  }
}

@media (max-width: 759px) {
  #headshot {
    display: none;
  }
  table > tr td {
    display: inline-block;
    width: 100%;
  }
  table > tr td input {
    width: 100%;
  }
  video {
    border-radius: 10px;
    margin-left: 0;
    margin-bottom: 55px;
    justify-content: center;
  }
  /* #project {
    margin: 100px 100px 100px 100px;
    line-height: 28px;
    padding-bottom: 100px;
  } */
}
